import { React, useState } from 'react';
import Grid from '@mui/material/Grid';
import ArcadePaper from '../../components/arcadepaper/ArcadePaper'
import ClipboardCopy from '../../components/clipboardcopy/ClipboardCopy'
import Stack from '@mui/material/Stack';
import GitHubIcon from '@mui/icons-material/GitHub';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import "./home.scss";

function goFullscreen() {
  if (document.documentElement.requestFullscreen) {
    document.documentElement.requestFullscreen();
  } else if (document.documentElement.webkitRequestFullscreen) {
    document.documentElement.webkitRequestFullscreen();
  }
}

function exitFullscreen() {
  if (document.fullscreenElement) {
    document.exitFullscreen()
  }
}



function Home() {
  const [fullscreen, setFullscreen] = useState(false);
  const emailAddress="mail@omohammed.com";

  const handleGitHubClick = (e) => {
      e.preventDefault();
      window.open('https://github.com/omarm61', '_blank');
  }

  const handleLinkedInClick = (e) => {
      e.preventDefault();
      window.open('https://www.linkedin.com/in/omar-mohammed-2a536b66/', '_blank');
  }

  const handleScrollDown = (e) => {
    document.getElementById('skills').scrollIntoView();
  }

  const handleFullscreenButton = () => {
    if (!fullscreen) {
      goFullscreen();
      setFullscreen(true);
    } else {
      exitFullscreen();
      setFullscreen(false);
    }
  }

  return (
    <div className="home" id="home">
      <button className="fullscreen-button" onClick={handleFullscreenButton}>{(fullscreen)?<FullscreenExitIcon />: <FullscreenIcon />}</button>
      <div className="content">
        <Grid
          container
          direction="row"
          sx={{
            justifyContent: "space-around",
            alignItems: "center",
            overflow: "hidden",
          }}
        >
          <Grid item lg={5}>
            <div className="intro-card">
              <h1 className="header-name">Omar Mohammed</h1>
              <h1 className="header-title">FPGA Developer</h1>
              <p className="body-summary">
                I’m an FPGA Engineer who loves working on challenging problems. 
                Turning challenging ideas into reliable, high-performance solutions is what drives me, and I’m always excited for the next big project.
              </p>
              
            </div>
          </Grid>
          <Grid item lg={3}>
            <Stack 
              spacing={4} 
              padding={2}
              direction={{ sm: "row", lg: "column" }}
            >
              <ArcadePaper sx="contact">
                <h2>Contact</h2>
                <ClipboardCopy value={emailAddress}/>
                <div className="links">
                  <button onClick={handleGitHubClick}><GitHubIcon sx={{ color: "lightgray" }} fontSize="large"/></button>
                  <button onClick={handleLinkedInClick}><LinkedInIcon sx={{ color: "lightgray" }} fontSize="large"/></button>
                </div>
              </ArcadePaper>
              <ArcadePaper sx="hotkeys" sm='hidden'>
                <h2>Hotkeys</h2>
                <table>
                  <tbody>
                    <tr>
                      <td className="td-right"> <div className="kbd-key">1</div> </td> 
                      <td><span>Intro</span></td> 
                    </tr>
                    <tr>
                      <td className="td-right"> <div className="kbd-key">2</div> </td> 
                      <td><span>Skills</span></td> 
                    </tr>
                    <tr>
                      <td className="td-right"> <div className="kbd-key">3</div> </td> 
                      <td><span>Work</span></td> 
                    </tr>
                    <tr>
                      <td className="td-right"> <div className="kbd-key">4</div> </td> 
                      <td><span>Projects</span></td> 
                    </tr>
                    <tr>
                      <td className="td-right"> <div className="kbd-key">SPACE</div> </td> 
                      <td><span>Next Page</span></td> 
                    </tr>
                  </tbody>
                </table>
              </ArcadePaper>
            </Stack>
          </Grid>
        </Grid>
      </div>
      <a onClick={handleScrollDown} className="arrow-container">
        <div className="arrow"></div>
        <div className="arrow"></div>
        <div className="arrow"></div>  
      </a>
    </div>
  );
}

export default Home;

