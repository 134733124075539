import React from 'react';
import Grid from '@mui/material/Grid';
import ArcadePaper from '../arcadepaper/ArcadePaper'
import { styled } from '@mui/material/styles';
import './jobcard.scss'

function JobCard(props){

  return(
    <ArcadePaper sx="job-card">
      <Grid
        container
        direction="column"
        sx={{
          justifyContent: "space-around",
          alignItems: "start",
        }}
      >
        <Grid item sm={12}>
          {props.job.title}
        </Grid>
        <Grid item sm={12}>
          {props.job.body}
        </Grid>
      </Grid>
    </ArcadePaper>
  );
};


export default JobCard
