import "./work.scss"
import Grid from '@mui/material/Grid';
import JobCard from "../../components/jobcard/JobCard"
import JobFolder from "../../components/jobfolder/JobFolder"
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import * as workDef from "./work_def.jsx"

const AntTabs = styled(Tabs)({
  borderBottom: '1px solid #e8e8e8',
  '& .MuiTabs-indicator': {
    backgroundColor: '#1890ff',
  },
});

function Work() {
  return (
    <div className="work" id="work">
      <Grid
        container
        direction="row"
        paddingTop={4}
        sx={{
          justifyContent: "space-around",
          alignItems: "center",
          height: "100vh",
          overflow: "hidden",
        }}
      >
        <Grid item md={12} display={{md: "block", lg: "none"}} sx={{height: "100%"}}>
          <JobFolder 
            jobs={[workDef.Lumi, workDef.Mda]}
            pages={["0", "1"]}
          />
        </Grid>
        <Grid item sm={5.5} display={{xs: "none", lg: "block"}}>
          <JobCard job={workDef.Lumi} />
        </Grid>
        <Grid item sm={5.5} display={{xs: "none", lg: "block"}}>
          <JobCard job={workDef.Mda} />
        </Grid>
      </Grid>
    </div>
  );
}

export default Work;

