import Topbar from "./components/topbar/Topbar";
import {
  Home,
  Skills,
  Projects,
  Work,
} from "./views/index"
import './app.scss';
import { useState, useEffect, Suspense } from "react";

const PageName = [
  "Intro",
  "Skills",
  "Work",
  "Projects",
]

function App() {
  const [pageNum, setPageNum] = useState(0);

  const handleScroll = (event) => {
    const position = event.target.scrollTop;
    setPageNum(Math.round(position/window.innerHeight))
  };

  // handle what happens on key press
  const handleKeyPress = (event) => {
    let num = Number(event.key);

    switch (num) {
      case 1:
        document.getElementById('home').scrollIntoView();
        break;
      case 2:
        document.getElementById('skills').scrollIntoView();
        break;
      case 3:
        document.getElementById('work').scrollIntoView();
        break;
      case 4:
        document.getElementById('projects').scrollIntoView();
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const container = document.getElementById('sections'); // target specific container
    container.addEventListener('scroll', handleScroll);
    document.addEventListener('keydown', handleKeyPress);

    return () => {
      container.removeEventListener('scroll', handleScroll);
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, []);

  return (
    <Suspense fallback={<div className="loading-screen">Loading components...</div>}>
      <div className="app" id="app">
        <Topbar page={PageName[pageNum]} />
        <div className="sections" id="sections">
          <div className="buffer"></div>
          <Home/>
          <Skills/>
          <Work/>
          <Projects/>
          <div className="buffer"></div>
        </div>
      </div>
    </Suspense>
  );
}

export default App;
