import Icon from '../../assets/LogicGateIcon';
import ImageEnhanceVideo from '../../assets/image_enhancement.mp4'
import DistortionCorrectionVideo from '../../assets/distortion_correction.mp4'
import PadlockImage from '../../assets/padlock.png'


export const ImageEnhance = {
  image: 
    <div className="image-container">
      <video src={ImageEnhanceVideo} autoPlay loop muted playsInline alt="Loading..."/>
    </div>
  ,
  body:
    <div>
      <h3>Image Enhancement</h3>
      <p> 
        Implemented Gamma Correction and a Sharpening Kernel in VHDL. The logic was simulated using ModelSim, and the video above was generated from the simulation.
      </p>
    </div>
  ,
  action: 
    <div>
      <button onClick={(e) => {
        e.preventDefault();
        window.open('https://github.com/omarm61/fpga_image_enhance', '_blank');
      }}>Github</button>
    </div>
};

export const DistortionCorrection = {
  image: 
    <div className="image-container">
      <video src={DistortionCorrectionVideo} autoPlay loop muted playsInline alt="Loading..."/>
    </div>
  ,
  body:
    <div>
      <h3>Distortion Correction</h3>
      <p>
        Developed a simple VHDL implementation of a barrel distortion correction algorithm.
      </p>
    </div>
  ,
  action:
    <div>
      <button onClick={(e) => {
        e.preventDefault();
        window.open('https://github.com/omarm61/fpga_distortion_correction', '_blank');
      }}>Github</button>
    </div>
};

export const ChaCha20 = {
  image: 
    <div className="image-container hide-height">
      <img src={PadlockImage} alt="Loading..."/>
    </div>
  ,
  body: 
    <div>
      <h3>ChaCha20 Encryption</h3>
      <p>RTL implementation of ChaCha20 encryption. This Project is in early stages of development, but you can still run the simulation and maybe monitor my progress.</p>
    </div>
  ,
  action:
    <div>
      <button onClick={(e) => {
        e.preventDefault();
        window.open('https://github.com/omarm61/fpga_chacha20', '_blank');
      }}>Github</button>
    </div>
};


export const Usb2Mipi = {
  image: 
    <div className="image-container hide-height">
    <div className="dummy-144"></div>
    </div>
  ,
  body:
    <div>
      <h3>USB2MIPI Adapter</h3>
      <p> PCB design for a USB to MIPI adapter used to evaluate camera modules. Currently only schematics is complete.</p>
    </div>
  ,
  action:
    <div>
      <button onClick={(e) => {
        e.preventDefault();
        window.open('https://github.com/omarm61/pcb_mipi_adapter', '_blank');
      }}>Github</button>
    </div>
};


