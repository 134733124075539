import { React, useState } from 'react';
import Grid from '@mui/material/Grid';
import './projectfolder.scss'



function ProjectFolder(props){
  const [page, setPage] = useState(0);

  return(
    <div className="project-folder">
      <nav>
        <ul>
          {props.pages.map((value, index) => {
            return <li key={index} className={(page === index)? "active" : ""} onClick={() => setPage(index)} ><a>{index}</a></li>
          })}
        </ul>
      </nav>
      <div className="folder-content">
        <Grid
          container
          direction="column"
          sx={{
            justifyContent: "space-around",
            alignItems: "center",
          }}
        >
          <Grid item xs={12} sx={{display: "flex", justifyContent: "center", width: "90%"}}>
            {props.projects[page].image}
          </Grid>
          <Grid item xs={12} sx={{width: "100%"}}>
            {props.projects[page].title}
            {props.projects[page].body}
          </Grid>
          <Grid item xs={12} className="button-container">
            {props.projects[page].action}
          </Grid>
        </Grid>

      </div>
    </div>
  );
}

export default ProjectFolder;
