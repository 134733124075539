import Grid from '@mui/material/Grid';
import ProjectCard from '../../components/projectcard/ProjectCard';
import ProjectFolder from '../../components/projectfolder/ProjectFolder';
import "./projects.scss";
import * as projectDef from "./projects_def.jsx"

function Projects() {

  return (
    <div className="projects" id="projects">
      <Grid
        container
        direction="row"
        paddingTop={4}
        sx={{
          justifyContent: "space-around",
          alignItems: "center",
          height: "100vh",
          overflow: "hidden",
        }}
      >
        <Grid item md={12} display={{md: "block", lg: "none"}} sx={{height: "100%"}}>
          <ProjectFolder 
            projects={[projectDef.ImageEnhance, 
                       projectDef.DistortionCorrection,
                       projectDef.ChaCha20,
                       projectDef.Usb2Mipi,
            ]}
            pages={["0", "1", "2", "3"]}
          />
        </Grid>
        {[
          projectDef.ImageEnhance,
          projectDef.DistortionCorrection,
          projectDef.ChaCha20,
          projectDef.Usb2Mipi,
        ].map((item, key)=> (
          <Grid item key={key} sm={5.5} xl={5} display={{xs: "none", lg: "block"}}>
            <ProjectCard project={item}/>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}

export default Projects;

