import React from 'react';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import './rating.scss'

/*
      {circles.map((x, index) => {
        if (index < props.value) {
          return <RadioButtonCheckedIcon fontSize="small"/>
        } else {
          return x
        }
      })}
*/

function Rating(props) {

  const circles = Array(8).fill()

  return(
    <div className="rating-container">
      <progress className="progress" value={props.value} max="100"></progress>
    </div>
  );
}

export default Rating;
