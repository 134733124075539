import lumi_logo from '../../assets/lumibird_logo_50x50.png'
import mda_logo from '../../assets/mda_logo_50x50.png'
import LaunchIcon from '@mui/icons-material/Launch';

export const Lumi = {
    title:
      <div>
        <p className="job-date">2021-Present</p>
        <div className="company-body">
          <img src={lumi_logo} />
          <div className="company-name-title">
            <h1>Lumibird</h1>
            <p>Electronics Engineer</p>
          </div>
          <div className="company-link">
            <a href="https://halo-photonics.com/" target="_blank">
              <LaunchIcon fontSize="small"/>
            </a>
          </div>
        </div>
      </div>
    ,
    body:
      <div className="job-body">
          <p className="underline bold margin-title">Summary</p>
          <p className="body">
            Contributed to the development of a Doppler LiDAR system, focusing on FPGA development and hardware-software integration.
          </p>
          <p className="underline bold margin-title mobile-hide">Achievments</p>
          <ul className="job-list mobile-hide">
            <li> Imrpoved system performance.</li>
            <li> Added seamless integration between hardware and software.</li>
            <li> Enhanced system verification and testing.</li>
          </ul>
          <p className="underline bold margin-title mobile-hide">Technologies</p>
          <ul className="job-list mobile-hide">
            <li> <span className="bold">Programming: </span>Verilog, SystemVerilog, C++/C, Python.</li>
            <li> <span className="bold">CPUs: </span>Xilinx RFSoC, RaspberryPi 4, ESP32.</li>
            <li> <span className="bold">Interfaces: </span> AXI, PCIe, USB3.0, Ethernet, WIFI, LVDS.</li>
            <li> <span className="bold">Tools: </span> Vivado, Verilator, Altium, LTSpice, Qucs.</li>
          </ul>
      </div>
  }

export const Mda = {
    title:
      <div>
        <p className="job-date">2017-2021</p>
        <div className="company-body">
          <img className="company-logo" src={mda_logo} />
          <div className="company-name-title">
            <h1>MDA Space</h1>
            <p>FPGA Engineer</p>
          </div>
          <div className="company-link">
            <a href="https://mda.space/" target="_blank">
              <LaunchIcon fontSize="small"/>
            </a>
          </div>
        </div>
      </div>
    ,
    body:
      <div className="job-body">
          <p className="underline bold margin-title">Summary</p>
          <p className="body">
            As an FPGA Developer, I specialize in designing and simulating logic for LiDAR and camera sensors. 
            My key projects include developing a high-definition camera and a miniature LiDAR system for navigation.
          </p>
          <p className="underline bold margin-title mobile-hide">Achievments</p>
          <ul className="job-list mobile-hide">
            <li> Solved timing issues on complex designs.</li>
            <li> Improved simulation by reaching high code coverage (&gt;80%).</li>
          </ul>
          <p className="underline bold margin-title mobile-hide">Technologies</p>
          <ul className="job-list mobile-hide">
            <li> <span className="bold">Programming: </span>VHDL, Verilog, SystemVerilog, Python.</li>
            <li> <span className="bold">CPUs: </span>ProASIC3, RTG4, Zynq-7000.</li>
            <li> <span className="bold">Interfaces: </span> UART, 1553, LVDS, AXI, AHB.</li>
            <li> <span className="bold">Tools: </span> Vivado, Libero, Incisive, Synopsys.</li>
          </ul>
      </div>
  }
