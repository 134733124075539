import React from 'react';
import Grid from '@mui/material/Grid';
import ArcadePaper from '../arcadepaper/ArcadePaper'
import { styled } from '@mui/material/styles';
import './projectcard.scss'


function ProjectCard(props){

  return(
    <ArcadePaper sx={"project-card"}>
      <Grid
        container
        direction="column"
        sx={{
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <Grid item sm={12} sx={{display: "flex", justifyContent: "center"}}>
          {props.project.image}
        </Grid>
        <Grid item sm={12} className="body-container">
          {props.project.body}
        </Grid>
        <Grid item sm={12} className="button-container">
          {props.project.action}
        </Grid>
      </Grid>

    </ArcadePaper>
  );
};


export default ProjectCard;
