import "./topbar.scss"
import {Home} from '@mui/icons-material';

function Topbar(props) {
  const handleButtonClick = (e) => {
    document.getElementById('home').scrollIntoView();
  }

  return (
    <div className="topbar">
      <div className="wrapper">

        <div className="left">
          <button className="btn" onClick={handleButtonClick} >
            <Home sx={{ color: "white" }} />
          </button>
          <span>
            {props.page}
          </span>
        </div>
      </div>
    </div>
  );
}

export default Topbar;
