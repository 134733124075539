import * as THREE from "three";
import { extend } from "@react-three/fiber";
import particlesVertexShader from './shaders/particles/vertex.glsl'
import particlesFragmentShader from './shaders/particles/fragment.glsl'

class CustomShaderMaterial extends THREE.ShaderMaterial {
  constructor() {
    super({
      vertexShader: particlesVertexShader,
      fragmentShader: particlesFragmentShader,
      uniforms: {
        uPointSize: new THREE.Uniform(2),
        uResolution: new THREE.Uniform(new THREE.Vector2(1,1)),
        uProgress: new THREE.Uniform(0),
        uColorA: new THREE.Uniform(new THREE.Color('#ff0000')),
        uColorB: new THREE.Uniform(new THREE.Color('#0000ff'))

      },
      transparent: true,
      blending: THREE.AdditiveBlending,
      depthWrite: false
    });
  }
}

extend({ CustomShaderMaterial });
