import React from 'react';
import './arcadepaper.scss'
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

function ArcadePaper({children = [], sx, sm}) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const smStyle = (isSmallScreen)? sm : '';

  return(
    <div className={`arcade-paper ${sx} ${smStyle}`}>
      {children}
    </div>
  );
}

export default ArcadePaper;
