import { React, useState } from 'react';
import Grid from '@mui/material/Grid';
import './jobfolder.scss'



function JobFolder(props){
  const [page, setPage] = useState(0);

  return(
    <div className="job-folder">
      <nav>
        <ul>
          {props.pages.map((value, index) => {
            return <li key={index} className={(page === index)? "active" : ""} onClick={() => setPage(index)} ><a>{value}</a></li>
          })}
        </ul>
      </nav>
      <div className="folder-content">
        <Grid
          container
          direction="column"
          sx={{
            justifyContent: "space-around",
            alignItems: "start",
          }}
        >
          <Grid item lg={4}>
            {props.jobs[page].title}
          </Grid>
          <Grid item lg={8}>
            {props.jobs[page].body}
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default JobFolder;
