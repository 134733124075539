import { React, useState } from 'react';
import './clipboardcopy.scss'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import Zoom from '@mui/material/Zoom';


const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: `rgba(black,0.7)`,
    color: 'rgba(black, 0.7)',
    boxShadow: theme.shadows[1],
    fontSize: 14,
    wordSpacing: 1,
    overflow: 'visible'
  },
  [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
  {
    marginBottom: '10px',
  },
}));


function ClipboardCopy(props) {
  const [done, setDone] = useState(false);

  const canHover = !(matchMedia('(hover: none)').matches);
  if (canHover) {
    document.body.classList.add('can-hover');
  }

  const handleCopyButton = (e) => {
    e.preventDefault();
    navigator.clipboard.writeText(props.value);
    setDone(true);
  }

  const handleMouseLeave = () => {
    setTimeout(() => {
      setDone(false);
    }, 4000); // wait 1 second before changing the value
  }

  return(
    <div className="clipboard-copy" onClick={handleCopyButton} onMouseLeave={handleMouseLeave}>
      <LightTooltip title={(done)? "Copied" : "Copy to Clipboard"} placement="top-end" TransitionComponent={Zoom} arrow>
        <form>
          <label className="hover-effect"> {props.value} </label>
          <button className="hover-effect"> {(done)? <CheckIcon sx={{ color: 'lightgreen' }} fontSize="small"/> : <ContentCopyIcon fontSize="small"/>} </button>
        </form>
      </LightTooltip>
    </div>
  );
}

export default ClipboardCopy;
